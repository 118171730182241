import {useEffect, useMemo, useState} from 'react';
import difference from 'lodash/difference.js';
import uniq from 'lodash/uniq.js';
import {
	createTimeString,
	daysMapping,
	renderLaterTimes,
	returnDayPartingErrors,
	weekDays,
	weekEnds,
} from './dayPartingHelper';
import {useAppDispatch, useAppSelector} from '../../../../../hooks';
import Select from "../../Fields/Select";
import Input from "../../Fields/Input";
import {addToDayParting, addToNetworkDayParting, changeSettingsField} from '../../../../../Redux/Slices/addCampaign';
import Toggle from "../../../../FormComponents/Toggle";
import {openSnackbar} from '../../../../../Redux/Slices/main';
import {checkConfirmBudget} from '../../../CampaignDetails/Settings/CampaignSettings/useSubmitSettings.tsx';
import DialogComponent from '../../../../../utils/UIElements/Dialog.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { ErrorList } from '@/utils/useErrorHelper.tsx';

export default function AddSettings({campaignDetails}: { campaignDetails: boolean }): JSX.Element {
	const dispatch = useAppDispatch();
	const {hasCpc, campaignID, network} = useAppSelector((state) => state.campaignDetails);
	const { settings, maximusDayParting, dayParting: currentDayParting, networkDayParting: currentNetworkDayParting } = useAppSelector((state) => state.addCampaign);
	const showCPCField = (!campaignDetails && (settings.bidStrategy === '1' || settings.bidStrategy === '2'))
		|| (campaignDetails && hasCpc);

	const [days, setDays] = useState<Array<string>>([]);
	const [startAt, setStartAt] = useState<string>('0');
	const [pauseAt, setPauseAt] = useState<string>('0');
	const [budget, setBudget] = useState<string>('');
	const [cpc, setCPC] = useState<string>('');
	const [enableBudget, setEnableBudget] = useState<boolean>(true);
	const [enableCPC, setEnableCPC] = useState<boolean>(showCPCField);
	const [pauseCampaign, setPauseCampaign] = useState<boolean>(false);
	const [errors, setErrors] = useState<object>({});
	const [confirmBudgetChangeMessage, setConfirmBudgetChangeMessage] = useState<string>('');

	useEffect(() => {
		resetFields();
	}, [maximusDayParting])

	const resetFields = () => {
		setDays<Array<object>>([]);
		setStartAt<string>('0');
		setPauseAt<string>('0');
		setBudget<string>('');
		setCPC<string>('');
	};

	const storeDayParting = (currentBudget = budget) => {
		dispatch(addToDayParting({
			value: {
				id: crypto.randomUUID(),
				days,
				startAt,
				pauseAt,
				budget: currentBudget,
				cpc,
				enableBudget,
				enableCPC,
				unixPauseAt: new Date(`2023-01-01 ${createTimeString(pauseAt)}:00`).getTime(),
			}
		}));
	}

	const storeNetworkDayParting = () =>
		dispatch(addToNetworkDayParting({
			value: {
				id: crypto.randomUUID(),
				days,
				startAt,
				pauseAt,
				status: !pauseCampaign,
				unixPauseAt: new Date(`2023-01-01 ${createTimeString(pauseAt)}:00`).getTime(),
			}
		}));

	const validateBudgetCPC = () => {
		let newConfirmBudgetMessage = '';
		if (showCPCField && enableCPC && enableBudget) {
			newConfirmBudgetMessage = checkConfirmBudget({network, budget, cpc, bidStrategy: settings.bidStrategy});
		}
		if (enableBudget && !enableCPC && showCPCField) {
			newConfirmBudgetMessage = checkConfirmBudget({
				network,
				budget,
				cpc: settings.cpc,
				bidStrategy: settings.bidStrategy
			});
		}
		if (!enableBudget && enableCPC) {
			newConfirmBudgetMessage = checkConfirmBudget({
				network,
				budget: settings.budget,
				cpc,
				bidStrategy: settings.bidStrategy
			});
		}
		setConfirmBudgetChangeMessage(newConfirmBudgetMessage);
		return !newConfirmBudgetMessage;
	};

	const validate = () => {
		const dayPartingObj = {
			days,
			startAt,
			pauseAt,
			budget,
			cpc,
			enableBudget,
			enableCPC,
		};

		const {
			valid,
			errors: validationErrors
		} = returnDayPartingErrors(dayPartingObj, network, showCPCField, maximusDayParting, maximusDayParting ? currentDayParting : currentNetworkDayParting);

		if (!valid) {
			setErrors(validationErrors);
			const children = <ErrorList>{Object.keys(validationErrors).map((key, i: number) => (
				<li key={`error-${i}`}>{validationErrors[key]}</li>))}</ErrorList>
			dispatch(openSnackbar({ children, severity: 'error' }));
			return;
		} else {
			setErrors({});
			if (maximusDayParting) {
				return validateBudgetCPC();
			}
			return true;
		}
	}

	async function save() {
		if (!validate()) {
			return;
		}
		if (maximusDayParting) {
			storeDayParting();
		} else {
			storeNetworkDayParting();
		}
		resetFields(maximusDayParting);
		dispatch(openSnackbar({children: 'Day Parting Setting Added', severity: 'success'}));
	}

	const updateBudgetRequest = async (newBudget: string) =>
		await networkRequest('api/v1/campaign/settings/budget/update', {
			campaignID,
			network,
			budget: newBudget,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					dispatch(openSnackbar({children: 'Budget was updated successfully.', severity: 'success'}));
					dispatch(changeSettingsField({name: 'budget', value: newBudget}));
				}
			});

	const renderWeekDayButtons = useMemo(() => {
		const weekDaysSelected = difference(weekDays, days).length === 0;

		const weekEndsSelected = difference(weekEnds, days).length === 0

		const setDaysByType = (day: string) => {
			if (day === 'week days') {
				if (difference(weekDays, days).length === 0) {
					setDays<Array<object>>(difference(days, weekDays));
				} else {
					setDays<Array<object>>(uniq([...days, ...weekDays]));
				}
				return true;
			}
			if (difference(weekEnds, days).length === 0) {
				setDays<Array<object>>(difference(days, weekEnds));
			} else {
				setDays<Array<object>>(uniq([...days, ...weekEnds]));
			}
			return true;
		}
		const formatSetDay = (selectedDay: string) => {
			if (days.indexOf<string>(selectedDay) > -1) {
				setDays(days.filter((day) => day !== selectedDay));
			} else {
				setDays([...days, selectedDay]);
			}
		};
		return Object.values(daysMapping<object>).map((day) => (
			<button
				type="button"
				key={day}
				onClick={() => {
					day.includes('week') ? setDaysByType(day) : formatSetDay(day);
					setErrors({...errors, days: ''});
				}}
				className={`weekday-button ${days.indexOf(day) > -1 ? 'selected-day-parting-day' : ''} ${day === 'week days' && weekDaysSelected ? 'selected-day-parting-day' : ''} ${day === 'week ends' && weekEndsSelected ? 'selected-day-parting-day' : ''}`}
			>
				{day}
			</button>
		))
	}, [days, errors]);

	const confirmChangeBudget = async () => {
		const calcCPC = enableCPC ? cpc : settings.cpc;
		const newBudget = (Number(calcCPC) * 30).toString();
		if (enableBudget) {
			setBudget(newBudget);
		} else {
			if (!campaignDetails) {
				dispatch(changeSettingsField({name: 'budget', value: newBudget || 0}));
			} else {
				await updateBudgetRequest(newBudget);
			}
		}
		setConfirmBudgetChangeMessage('');
		storeDayParting(newBudget);
		resetFields(maximusDayParting);
		dispatch(openSnackbar({children: 'Day Parting Setting Added', severity: 'success'}));
	};

	return (
		<div className="day-parting-container">
			<div className="week-day-button-container">
				{renderWeekDayButtons}
			</div>

			{maximusDayParting &&
				<div className="add-campaign-field-row pause-campaign-toggle">
					<Toggle
						trackColor="#4285F4"
						value={pauseCampaign}
						label="Pause Campaign"
						disabled={!maximusDayParting}
						onChange={(checked) => {
							setPauseCampaign(checked);
							if (checked) {
								setEnableCPC(false);
								setEnableBudget(false);
								setErrors({...errors, cpc: '', budget: ''});
							}
						}}
						name="pause-campaign"
					/>
				</div>
			}
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => {
						if (+value >= +startAt) {
							setStartAt('0');
						}
						setPauseAt(value);
						setErrors({...errors, pauseAt: ''});
					}}
					label={pauseCampaign || !maximusDayParting ? `Pause At` : 'Start At'}
					value={pauseAt}
					helperText={errors.pauseAt}
					error={Boolean(errors.pauseAt)}
					name="pause-at"
					options={renderLaterTimes(pauseAt, maximusDayParting)}
					size={{m: '12px', width: 'calc(50.33% - 24px)'}}
				/>
				<Select
					onChange={({target: {value}}) => {
						setStartAt(value);
						setErrors({...errors, startAt: ''});
					}}
					label={pauseCampaign || !maximusDayParting ? `Start At` : 'End At'}
					value={startAt}
					name="start-at"
					helperText={errors.startAt}
					error={Boolean(errors.startAt)}
					options={renderLaterTimes(pauseAt, maximusDayParting, true)}
					size={{m: '12px', width: 'calc(50.33% - 24px)'}}
				/>
			</div>
			{maximusDayParting &&
				<div className="add-campaign-field-row">
					<Input
						onChange={({target: {value}}) => {
							setBudget(value);
							setErrors({...errors, budget: ''});
						}}
						label="Budget"
						value={budget}
						name="day-parting-budget"
						helperText={errors.budget}
						error={Boolean(errors.budget)}
						numeric
						startAdornment={<span>$</span>}
						size={{m: '12px', width: 'calc(50.33% - 24px)'}}
						disabled={!enableBudget}
						endAdornment={
							<Toggle
								trackColor="#4285F4"
								value={enableBudget}
								onChange={(checked) => {
									setEnableBudget(checked);
									setErrors({...errors, budget: ''});
								}}
								name="enable-budget"
								disabled={pauseCampaign}
							/>
						}
					/>
					{showCPCField &&
						<Input
							onChange={({target: {value}}) => {
								setCPC(value);
								setErrors({...errors, cpc: ''});
							}}
							label="CPC"
							value={cpc}
							numeric
							helperText={errors.cpc}
							error={Boolean(errors.cpc)}
							name="day-parting-cpc"
							startAdornment={<span>$</span>}
							size={{m: '12px', width: 'calc(50.33% - 24px)'}}
							disabled={!enableCPC}
							endAdornment={
								<Toggle
									trackColor="#4285F4"
									value={enableCPC}
									onChange={(checked) => {
										setEnableCPC(checked);
										setErrors({...errors, cpc: ''});
									}}
									name="enable-cpc"
									disabled={pauseCampaign}
								/>
							}
						/>}
				</div>}
			{!!confirmBudgetChangeMessage &&
				<DialogComponent
					dialogOpen
					title="Confirm"
					useConfirm={confirmChangeBudget}
					useCancel={() => setConfirmBudgetChangeMessage('')}
				>
					<p>{confirmBudgetChangeMessage}</p>
				</DialogComponent>}
			<button
				type="button"
				className="add-day-parting-button"
				onClick={save}
			>
				Create Day Parting Setting
			</button>
		</div>
	);
}
