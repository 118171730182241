import { AddCampaignState } from '../../../../../Redux/Slices/addCampaign.ts';
import { isRevContent, isTaboola, Network } from '../../../../../utils/network-utils.ts';
import {ValidateStepProps} from "../StepsValidation.tsx";

export const cpcValidation = (cpc: string, network: Network) => {
	if (isRevContent(network) && (Number(cpc) < 0.001 || Number(cpc) > 3)) {
		return 'CPC needs to be between $0.001 and $3.';
	}
	if (Number(cpc) < 0.01 || Number(cpc) > 5) {
		return 'CPC needs to be between $0.01 and $5.';
	}
	return ''
}

export const budgetCPCTaboolaValidation = (budget: string, cpc: string, bidStrategy: string) =>
	(bidStrategy === '1' || bidStrategy === '2') && (Number(budget) < 30 * Number(cpc));

export const targetCpaValidation = (targetCPA: string) => {
	const min = 0.01;
	const max = 100;
	if (Number(targetCPA) < min || Number(targetCPA) > max) {
		return `Target CPA needs to be between $${min.toFixed(2)} and $${max.toFixed(2)}.`;
	}
	return ''
}

export const budgetValidation = (budget: string, network: Network) => {
	let message = '';
	if (isRevContent(network) && Number(budget) < 50) {
		message = 'Budget needs to be greater than $50.';
	}
	else if (Number(budget) < 10) {
		message = 'Budget needs to be greater than $10.';
	}
	return message;
}

export const validateCampaignSettings = (settings: AddCampaignState['settings'], network: Network, campaignDetails = false): ValidateStepProps => {
	const errors: ValidateStepProps = {fields: {}};
	if (!campaignDetails) {
		if (!settings.campaignName) {
			errors.fields.campaignName = 'Campaign Name should not be empty.';
		}
		if (settings.campaignName.length > 200) {
			errors.fields.campaignName = 'Campaign Name should not be more than 200 symbols.';
		}
		if (/[^a-zA-Z0-9_ ]/.test(settings.campaignName)) {
			errors.fields.campaignName = 'Special characters are not allowed in the name.';
		}
		if (settings.group.toString() === '0') {
			errors.fields.group = 'Please select a group.';
		}
	}
	if (settings.isOttoCampaign) {
		if (!settings.affiliate) {
			errors.fields.affiliate = 'Please enter a value for OTTO Affiliate ID.';
		}
		if (!settings.s1) {
			errors.fields.s1 = 'Please enter a value for OTTO S1.';
		}
	}
	if (settings.platformTargeting.length === 0) {
		errors.fields.platformTargeting = 'Please select a value for platform targeting.';
	}

	if (settings.brandingText.length > 25) {
		errors.fields.brandingText = 'Branding Text should not be more than 25 symbols.';
	}
	if (!settings.brandingText) {
		errors.fields.brandingText = 'Please enter a value for branding text.';
	}
	if (budgetValidation(settings.budget, network)) {
		errors.fields.budget = budgetValidation(settings.budget, network);
	}

	if (isTaboola(network)) {
		if (!campaignDetails) {
			if (settings.taboolaAccount.toString() === '0') {
				errors.fields.taboolaAccount = 'Please select an account.';
			}
			if (settings.taboolaSubAccount.toString() === '0') {
				errors.fields.taboolaSubAccount = 'Please select a sub account.';
			}
		}
		if (settings.bidStrategy === '4') {
			if (targetCpaValidation(settings.targetCPA)) {
				errors.fields.targetCPA = targetCpaValidation(settings.targetCPA)
			}
			if (settings.creativeTrafficAllocation === '1') {
				errors.fields.creativeTrafficAllocation = 'Traffic Allocation mode could not be Even when Bid Strategy is Target CPA.';
			}
			if (settings.marketingObjective === '1') {
				errors.fields.marketingObjective = 'Marketing Objective cannot not be set to Brand Awareness when Bid Strategy is Target CPA.';
			}
		}
		if (!(['3', '4'].includes(settings.bidStrategy)) && cpcValidation(settings.cpc, network)) {
			errors.fields.cpc = cpcValidation(settings.cpc, network);
		}
	}

	if (isRevContent(network)) {
		if (!campaignDetails) {
			if (settings.usmID.toString() === '0') {
				errors.fields.usmID = 'Please select an account.';
			}
		}
		if (cpcValidation(settings.cpc, network)) {
			errors.fields.cpc = cpcValidation(settings.cpc, network);
		}
	}

	return errors;
};