import {useEffect} from 'react';
import {Routes, Route} from "react-router-dom";
import ManageCampaigns from "../Components/Pages/ManageCampaigns";
import CampaignDetails from "../Components/Pages/CampaignDetails";
import Login from "../Components/Pages/Login";
import Logout from "../Pages/logout";
import AddCampaign from "../Pages/add_campaign";
import useAuth from "../utils/useAuth";
import PageLoader from "../Components/Loaders/PageLoader";
import {useAppSelector} from "../hooks";
import useInitializeApp from "../utils/useInitializeApp";
import CloneCampaign from "../Pages/clone_campaign";
import HelpDocs from '../Pages/help_docs.tsx';

export const Router = () => {
	const {appLoaded} = useAppSelector((state) => state.main);
	const {theme} = useAppSelector((state) => state.main.userSettings);
	useAuth();
	useInitializeApp();

	useEffect(() => {
		document.body.className = theme;
	}, [theme]);

	return !appLoaded ? <PageLoader /> : (
		<Routes>
			<Route index element={<ManageCampaigns/>}/>
			<Route key="/campaign_details/:id" path="/campaign_details/:id" element={<CampaignDetails/>}/>
			<Route key="/login" path="/login" element={<Login/>}/>
			<Route key="/logout" path="/logout" element={<Logout/>}/>
			<Route key="/add_campaign_taboola" path="/add_campaign_taboola" element={<AddCampaign/>}/>
			<Route key="/add_campaign_revcontent" path="/add_campaign_revcontent" element={<AddCampaign/>}/>
			<Route key="/add_campaign_outbrain" path="/add_campaign_outbrain" element={<AddCampaign/>}/>
			<Route key="/clone_campaign_taboola/:id" path="/clone_campaign_taboola/:id" element={<CloneCampaign />}/>
			<Route key="/clone_campaign_revcontent/:id" path="/clone_campaign_revcontent/:id" element={<CloneCampaign />}/>
			<Route key="/help" path="/help" element={<HelpDocs />} />
		</Routes>
	)
}
