import { useAppDispatch, useAppSelector } from '@/hooks'
import { openSnackbar } from '@/Redux/Slices/main'
import networkRequest, { networkRequestMultipart } from '@/utils/networkRequest'
import { useState } from 'react'

function dicerRequest(path: string, body: any) {
	return fetch(`https://dicerai.app.n8n.cloud/webhook/${path}`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Authorization: '4uX6A2pUjcnVrG2AVFAjewEHDby3tvReLRMsRXkKZt4qD7hRwiFPc9ganFhoTiFd',
		},
	}).then(res => {
		if (!res.ok) {
			throw new Error('Failed to fetch')
		}
		return res.json()
	})

	// if (path.includes('image')) {
	// 	return Promise.resolve(
	// 		{
	// 			s3Locations: [
	// 				'https://dicer-usm.s3.us-east-1.amazonaws.com/skin%2Fdicer_fe5j2nmb6nrg80cjqvyax2pegw',
	// 			]
	// 		}
	// 	)
	// }
	//
	// return Promise.resolve(
	// 	{
	// 		headlines: [
	// 			'test headline'
	// 		]
	// 	}
	// )
}

export function useAiGenerate() {
	const dispatch = useAppDispatch()
	const network = useAppSelector(state => state.campaignDetails.network)
	const isOtto = useAppSelector(state => state.campaignDetails.isOtto)
	const [loading, setLoading] = useState(false)

	const uploadFile = async (url: string) => {
		const response = await networkRequest('api/proxy', { url }, 'GET').catch(() => {
			dispatch(
				openSnackbar({ children: 'Failed to fetch file through Maximus proxy', severity: 'error' })
			)
		})
		if (!response) return ''
		const blob = await response.blob()
		const file = new File([blob], url, { type: blob.type })

		return networkRequestMultipart(
			'api/v1/campaign/fileUpload/image',
			{ file, network },
			'https://creatives.maximusx.app'
		)
			.then(response => response.json())
			.then(res => res.url)
			.catch(() => {
				dispatch(openSnackbar({ children: 'Failed to upload external file', severity: 'error' }))
				return ''
			})
	}

	const fetchAiData = async (settings: {
		variants: number
		descriptionsPrompt: string
		headlinesPrompt: string
		imagesPrompt: string
		useAi: {images: boolean, headlines: boolean, descriptions: boolean},
		aspectRatio: '1:1' | '16:9' | '2:3' | '3:2' | '4:5' | '5:4' | '9:16' | '3:4' | '4:3'
	}) => {
		setLoading(true)

		const [descriptionsRes, headlinesRes, imagesRes] = await Promise.all([
			settings.useAi.descriptions
				? dicerRequest('usm-native-headline', { // TODO: use description route when available, and adjust response body format
						variants: settings.variants,
						userComments: settings.descriptionsPrompt || '',
						offerId: isOtto ? '854f5785-83f7-4729-b3c9-54154c4793a7' : '63d9c669-2788-47c3-a915-b0a2e7a1610a',
					})
				: Promise.resolve({ headlines: [] }),
			settings.useAi.headlines
				? dicerRequest('usm-native-headline', {
						variants: settings.variants,
						userComments: settings.headlinesPrompt || '',
						offerId: isOtto ? '854f5785-83f7-4729-b3c9-54154c4793a7' : '63d9c669-2788-47c3-a915-b0a2e7a1610a',
					})
				: Promise.resolve({ headlines: [] }),
			settings.useAi.images
				? dicerRequest('usm-native-image', {
						variants: settings.variants,
						userComments: settings.imagesPrompt || '',
						aspectRatio: settings.aspectRatio,
						imageStyle: 'Photo Realistic',
						offerId: isOtto ? '854f5785-83f7-4729-b3c9-54154c4793a7' : '63d9c669-2788-47c3-a915-b0a2e7a1610a',
					})
				: Promise.resolve({ s3Locations: [] }),
		]).catch(() => {
			dispatch(openSnackbar({ children: 'Failed to pull AI data', severity: 'error' }))
			return [{ headlines: [] }, { headlines: [] }, { s3Locations: [] }]
		})

		const images: { dicerFile: string; image: string }[] = []
		const headlines: string[] = headlinesRes.headlines
		const descriptions: string[] = descriptionsRes.headlines

		for (const dicerFile of imagesRes.s3Locations) {
			const image = await uploadFile(dicerFile)
			images.push({ dicerFile, image })
		}
		setLoading(false)

		return { images, headlines, descriptions }
	}

	return {
		loading,
		fetchAiData,
	}
}
